import * as React from 'react';
import styled from '../../utils/styled';
import { RadioButtonGroup, RadioButtonGroupProps } from '../RadioButtonGroup';
const TileContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -10px -10px 0;
`;
export const MetroRadioGroup = Object.assign((props => (<TileContainer>
    <RadioButtonGroup {...props}/>
  </TileContainer>)) as React.SFC<RadioButtonGroupProps>, { inner: {
        get TileContainer() { return TileContainer as typeof TileContainer; },
        get RadioButtonGroup() { return RadioButtonGroup as typeof RadioButtonGroup; }
    } });
MetroRadioGroup.displayName = 'MetroRadioGroup';
